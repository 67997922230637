@import '../../../../scss/utilities';

.content {
  @include make-container();
  @include media-breakpoint-up(md) {
    @include make-container-max-widths();
  }
}

.content__wrapper {
  @include make-row();
  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }
}

.content__main {
  @include make-col-ready();
  @include make-col(12);
  // Set min width/height to support react-slick
  min-width: 0;
  min-height: 0;
  @include media-breakpoint-up(lg) {
    @include make-col-auto();
    flex: 1 1 auto;
  }
}

.content__shadowbox {
  background-color: $ue-white;
  @include media-breakpoint-up(md) {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 30px $ue-shadow;
  }
}

.content__side {
  display: none; // react-responsive fallback
  @include media-breakpoint-up(lg) {
    @include make-col-ready();
    @include make-col-auto();
    max-width: #{$ue-sidebar-width + $grid-gutter-width};
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Must specify for sticky items
    flex: 0 0 #{$ue-sidebar-width + $grid-gutter-width};
  }
}
